<template>
  <div>
    <v-container class="header mx-lg-auto d-flex flex-column align-center justify-center my-5">
      <h1 :class="$vuetify.display.mdAndDown ? 'px-4' : ''">Réduisons les aléas des chantiers de <span class="reha-green">réha</span><span class="reha-blue">bilitation</span> ensemble.</h1>
      <h2 :class="$vuetify.display.mdAndDown ? 'px-4' : ''">Fly2success, Khephren Ingénierie, CORELO, WO2, ALTO Ingénierie lancent l’initiative Rehality, avec l’objectif
        de repositionner le nuage de point 3D, source la plus fiable des données de l’existant, au cœur des études.</h2>
      <div class="inscription-cta mx-4">
        <p>Inscrivez-vous dès maintenant pour suivre l’avancement de la démarche :</p>
        <div class="d-flex flex-column flex-md-row" style="gap: 16px; align-items: center; align-self: stretch;">
          <v-text-field @keyup.enter="register()" :error-messages="errors" v-model="email" style="align-self: stretch;" class="m-0" type="email"  placeholder="prenom.nom@societe.com" label="Email"></v-text-field>
          <v-btn :disabled="processing" @click="register()">Rejoindre l’initiative</v-btn>
        </div>
      </div>
    </v-container>
    <v-container :class="`logos py-10 ${$vuetify.display.mdAndUp ? 'px-15' : 'px-4'}`" fluid >
      <div class="logo-slide">
        <template v-for="index in 2" :key="index">
          <v-img @click='open("https://fly2success.io");' src="@/assets/logo/logo_Fly2success_carre.svg" style="max-width:150px;"></v-img>
          <v-img @click="open('https://khephren.fr/')" src="@/assets/logo/khephren.svg" style="max-width:200px"></v-img>
          <v-img @click="open('https://www.wo2.com/')" src="@/assets/logo/wo2.svg" style="max-width:200px"></v-img>
          <v-img @click="open('https://alto-ingenierie.fr/')" src="@/assets/logo/Logo_ALTO_HD.jpg" style="max-width:150px"></v-img>
          <v-img @click="open('https://corelo.fr/')" src="@/assets/logo/logo-corelo-footer.svg" style="max-width:200px"></v-img>
        </template>
      </div>
    </v-container>
    <v-snackbar :color="colorMessage" v-model="showMessage" :timeout="10000">
      {{ userFeedback }}
      <template v-slot:actions>
        <v-btn  variant="text" @click="showMessage = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>


</template>

<script lang="ts">

  import { defineComponent } from 'vue'
  import axios from "axios";


  export default defineComponent({
    data() {
      return {
        email: "",
        processing: false,
        errors: [],
        userFeedback: "",
        showMessage: false,
        colorMessage: ""
      }
    },
    methods: {
      open(url) {
        window.open(url,'_blank');
      },
      register() {
        this.processing = true;
        axios.post("/subscriptions.json", { email: this.email }).catch( (error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            if (error.response.data?.errors?.email) {
              this.errors = error.response.data?.errors?.email.map( (e) => `L'email ${e}`);
            }
            console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          this.colorMessage = "error"
          this.userFeedback = "Une erreur s'est produite"
          this.processing = false;
          this.showMessage = true;
        }).then((response) => {
          if (response) {
            if (response.data.status == 200) {
              console.log(response.data)
              this.email = "";
              this.errors = [];
              this.colorMessage = "secondary"
              this.userFeedback = "Votre inscription a bien été prise en compte"
              this.showMessage = true;
            }
          }
          this.processing = false;
        })
      }
    }
  })

</script>

<style lang="scss">
  @keyframes slide {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-50%);
    }
  }

  .v-main {
    .reha-green {
      color: rgb(var(--v-theme-secondary));
    }

    .reha-blue {
      color: rgb(var(--v-theme-primary));
    }

    .header {
      display: flex;
      padding: var(--spacing-spacing-none, 0px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-6, 40px);
      align-self: stretch;
      text-align: center;


      h1 {
        font-size: clamp(24px, 4vw, 64px);
      }

      h2 {
        font-size: clamp(16px, 2vw, 32px);
        font-weight: 300;
      }

      .inscription-cta {
        display: flex;
        padding: 32px;

        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 5px;
        background: var(--color-white-white-10, rgba(255, 255, 255, 0.10));

        input {
          align-self: stretch;
        }

        .v-input__details {
          // display: none;
          text-align: left;
        }

        p {
          color: #FFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
      }

    }

    .logos {
      padding-left: 8px;
      padding-right: 8px;

      .logo-slide{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        animation: 30s slide infinite linear;
        width: 200%;
        gap: 8px;
      }

      .logo-slide:hover {
        animation-play-state: paused;
      }



      .v-img {
        padding-left: 4px;
        cursor: pointer;
        padding-right: 4px;
      }
    }
  }





</style>