<template>
  <v-app id="homepage">
    <nav-bar></nav-bar>
    <v-main style="margin-top: 60px;">
      <home-page v-if="this.now && launchDate.diff(this.now) < 0"></home-page>
      <launch v-if="this.now && launchDate.diff(this.now) > 0"></launch>
    </v-main>
    <footer-view></footer-view>
  </v-app>
</template>


<script lang="ts">

import { defineComponent } from 'vue'

import NavBar from './_nav.vue';
import FooterView from './_footer.vue';
import Launch from '../launch.vue'
import HomePage from '../page/home.vue'
import { useDate } from 'vuetify'
import duration from 'dayjs/plugin/duration'
import dayjs from 'dayjs'
const LAUNCH_DATE = dayjs("12/12/2023 9:00");


export default defineComponent({
  components: {
    'nav-bar': NavBar,
    'launch': Launch,
    'home-page': HomePage,
    'footer-view': FooterView
  },
  data() {
    return {
      now: undefined,
      launchDate: LAUNCH_DATE
    }
  },
  created() {
    this.updateNow()
  },
  methods: {
    updateNow() {
      let rehality = window.location.href.split('?').splice(-1)[0]

      if (rehality == "rehality=now") {
        this.now = dayjs("12/12/2023 10:00");
      } else {
        this.now = dayjs();
        setTimeout(this.updateNow, 500);
      }
    }
  }
})
</script>

<style lang="scss">
  @font-face {
    font-family: "Inter";
    src: url("@/assets/fonts/Inter-VariableFont_slnt,wght.ttf") format('truetype');
    font-weight: 125 950;
    font-stretch: 75% 125%;
    font-style: normal;
  }





</style>