<template>
  <footer>
    <v-container class="mx-auto d-flex align-center justify-center">
      Copyright © Rehality
    </v-container>
  </footer>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: {},
    data() {
    return {
      availableLocales: window.I18n.availableLocales,
      locale: window.I18n.locale
    }
  },

  methods: {
    activeOn(paths) {
    //   return paths.includes(this.$route.name) ? 'active' : ''
    }
  },

  watch: {
    locale: function(locale) {
      let redirectTo = `/${locale}${this.$route.path}`;
      if(locale == this.availableLocales[0]) {
        redirectTo = `${this.$route.path}`
      }
      window.location.href = redirectTo;
    }
  }
})

</script>

<style>

  footer {
    min-height: 100px;

    .v-container {
      border-top: 1px solid var(--color-white-white-10, rgba(255, 255, 255, 0.10));
    }
  }
</style>