<template>
  <v-container class="mx-auto d-flex flex-column align-center justify-center">
    <h1 class="big my-15">Bientôt en ligne</h1>
    <div v-if="now" class="countdown d-flex justify-space-around">
      <div class="d-flex flex-column" >
        <div class="d-flex align-center justify-center number my-auto">{{ timeLeft().days() }}</div>
        <div class="label">Jours</div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex align-center justify-center number my-auto">{{ timeLeft().hours() }}</div>
        <div class="label">Heures</div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex align-center justify-center number my-auto">{{ timeLeft().minutes() }}</div>
        <div class="label">Minutes</div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex align-center justify-center number my-auto">{{ timeLeft().seconds() }}</div>
        <div class="label">Secondes</div>
      </div>
    </div>
  </v-container>
</template>


<script lang="ts">

import { defineComponent } from 'vue'

import { useDate } from 'vuetify'
import duration from 'dayjs/plugin/duration'
import dayjs from 'dayjs'
const LAUNCH_DATE = dayjs("12/12/2023 9:00");


export default defineComponent({
  data() {
    return {
      now: undefined
    }
  },
  created() {
    setTimeout(this.updateNow, 500);
  },
  methods: {
    timeLeft() : duration.Duration {
      if (this.now === undefined) {
        return dayjs.duration(0);
      }

      dayjs.extend(duration)
      const d = LAUNCH_DATE.diff(this.now)
      return dayjs.duration(d);
    },
    updateNow() {
      this.now = dayjs();
      setTimeout(this.updateNow, 500);
    }

  }
})
</script>

<style lang="scss">
    h1.big {
      font-size: 5vw;
      text-align: center;
      font-weight: bolder;
    }

    body #homepage {

      .countdown {
        overflow: hidden;
        // gap: 1vw;
        text-align: center;
        .number {
          background-color: rgb(var(--v-theme-background));
          aspect-ratio: 1 / 1 ;
          width: 20vw;
          max-width: 250px;
          font-size: clamp(10px, 12vw, 128px);
        }

        .label {
          font-size: 3vw;
          font-weight: 300;
        }
      }
}

</style>