// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import Layout from '@/views/shared/layout.vue';
import { VueElement, createApp } from 'vue';
const app = createApp(Layout);

// import Router from '@/routes.js';
// import Layout from '@/views/shared/layout.vue';
import Axios from "axios";

// ActionCable setup
import { createCable } from '@/plugins/cable';
// const Cable = createCable({channel: 'ChatChannel'})

// Form animations + Axios
import { createApi } from '@/plugins/api';
const Api = createApi({handler: Axios, namespace: ''});

// Pinia + Axios setup
import { createPinia } from 'pinia';
const Pinia = createPinia();
Pinia.use(({ store }) => { store.axios = Axios })

// I18n loader
import { createI18n } from 'vue-i18n';
const I18n = createI18n({locale: window.I18n.locale,  messages: translations});



const rehalityDark: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#18151F',
    surface: '#3377F1',
    primary: '#3377F1',
    'primary-darken-1': '#3700B3',
    secondary: '#54E0A4',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    text: '#FFFFFF'
  },
}

// Vuetify
import 'vuetify/styles'
import { createVuetify, type ThemeDefinition  } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { mdiHeart, mdiDotsVertical } from '@mdi/js'
import { en, fr } from 'vuetify/locale'
// import { VuetifyDateAdapter } from 'vuetify/date/adapters/vuetify'




const vuetify = createVuetify({
  // ssr: true,
  // date: {
  //   adapter: VuetifyDateAdapter
  // },
  display: {
    mobileBreakpoint: 'md',
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 800,
      xl: 1280,
    },
  },
  theme: {
    defaultTheme: 'rehalityDark',
    variations: {
      colors: ['primary', 'secondary', 'background'],
      lighten: 2,
      darken: 2,
    },
    themes: {
      rehalityDark
    }
  },
  locale: {
    locale: window.I18n.locale,
    fallback: 'fr',
    messages: { fr, en, translations },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      heart: mdiHeart,
      menu: mdiDotsVertical
    },
    sets: {
      mdi,
    },
  },
})

app.use(Pinia)
  .use(I18n)
  .use(Api)
  .use(vuetify)
  // .use(Cable)
  .mount('#app')