<template>
  <section class="top-nav">
    <v-app-bar flat class="py-5" color="background">
      <v-container class="nav mx-auto d-flex align-center justify-center">
        <v-img src="@/assets/logo/logo_rehality_long.svg" style="max-width:200px"  cover></v-img>
      </v-container>
    </v-app-bar>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: {},
    data() {
    return {
      availableLocales: window.I18n.availableLocales,
      locale: window.I18n.locale
    }
  },

  methods: {
    activeOn(paths) {
    //   return paths.includes(this.$route.name) ? 'active' : ''
    }
  },

  watch: {
    locale: function(locale) {
      let redirectTo = `/${locale}${this.$route.path}`;
      if(locale == this.availableLocales[0]) {
        redirectTo = `${this.$route.path}`
      }
      window.location.href = redirectTo;
    }
  }
})

</script>

<style>
  header.v-toolbar .nav {
    border-bottom: 1px solid var(--color-white-white-10, rgba(255, 255, 255, 0.10));
  }
</style>